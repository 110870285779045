.swiper3 {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.swiper-slide img {
    object-fit: cover;
    object-position: center;
    width: auto;
    height: 500px;
    margin: 0 auto;
}

.swiper3 .swiper-button-prev,
.swiper3 .swiper-button-next {
    color: white;
}
.swiper-pagination-bullet {
    background: black;
}
.swiper-pagination-bullet-active {
    background: black;
}

.pagination {
    position: absolute;
    width: 85%;
}

.pagination div[class^="swiper-button"]::after {
    display: none;
}

@media only screen and (min-width: 768px)  {

 
}

@media only screen and (min-width: 0px) and (max-width: 768px) {

   
  



}