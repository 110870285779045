.swiper2 {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 1200px;
    align-items: center;
    padding: 0px 0;
    height: 200px;
}


.swiper2 .swiper-slide {
    text-align: center;
    margin: auto 0;
}


.swiper2 .swiper-button-prev,
.swiper2 .swiper-button-next {
    color: #9d6b53;
}

.swiper2 .swiper-pagination-bullet {
    background: #4f5186;
}
.swiper2 .swiper-pagination-bullet-active {
    background: #4f5186;
}

.pagination {
    position: absolute;
    width: 85%;
}

.pagination div[class^="swiper-button"]::after {
    display: none;
}


@media only screen and (min-width: 0px) and (max-width: 768px) {
    .swiper2 {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        max-width: 1200px;
        align-items: center;
        padding: 0px 0;
        height: 200px;
    }
}