@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oleo+Script&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

}

.oleo {
    font-family: 'Oleo Script', cursive;
}

.smplusvisible {
    display: none;

}

@media (min-width: 1024px) {
    .smplusvisible {
        display: flex;

    }
}

.list-item {
    text-indent: -1.5em;
    /* Adjust the value as needed */
    padding-left: 1.5em;
    /* Add some padding to the left */
}

.imgBg {
    background-image: url('../src/images/bg.jpg');
    /* Other CSS properties for styling your .imgBg class */
}

@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.customFont {
    font-family: 'Varela Round', sans-serif;
}

.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    /* You can adjust this value as needed to control stacking order */
    background-color: white;
    /* Set your desired background color */
    /* Add any other styles you want for the fixed navbar, e.g., padding, box-shadow, etc. */
}

.back-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: #ed9aa7;
    color: #fff;
    font-size: 24px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    opacity: 0;
    pointer-events: none;
}

.back-to-top-button.visible {
    opacity: 1;
    pointer-events: auto;
}

.back-to-top-button:hover {
    background-color: #0056b3;
}





